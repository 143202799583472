import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { Button, Form, Input } from "antd";
import {
  ValidateOrganizationCareerPageURL,
  ValidateOrganizationCompanyName,
  ValidateOrganizationDisplayName,
  initOrganisationSetup,
} from "../../lib/api";

import {
  current_org,
  showOrg,
  tokenExchange,
  updateOrgNameId,
} from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useHistory } from "react-router-dom";
import { Role } from "../../lib/apiRes";

type nameProps = {
  loginUser: loginUser;
  UsernameForPreview(name: string): void;
  addOrg(orgData: any): void;
  updateIdName(orgData: any): void;
  tokenExchange(
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    application_roles: Role[]
  ): void;
  currentOrg(orgData: any): void;
};

const SetupOrgname: React.FC<nameProps> = ({
  loginUser,
  UsernameForPreview,
  updateIdName,
  addOrg,
  currentOrg,
  // tokenExchange
}) => {
  const [username, setUsername] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [loadingState, setLoadingState] = useState({
    createNewOrgButton: false,
  });

  const [form] = Form.useForm();
  const history = useHistory();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    sessionStorage.removeItem("orgCreated");
  }, []);


  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    // Function to perform validation and update state
    const validateAndSetButtonState = () => {
      if(username.length>0 && companyName.length>0 && companyUrl.length>0){

        form.validateFields().then(
          () => setIsButtonDisabled(false),
          () => setIsButtonDisabled(true)
        );
      }
      else{
        setIsButtonDisabled(true)
      }
    };
  

    // Function to debounce the validate function
    const debounceValidate = () => {
      clearTimeout(debounceTimer);
            debounceTimer = setTimeout(validateAndSetButtonState, 1000);
    };
    debounceValidate();
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [form, username, companyName, companyUrl]);

  
  const onFinish = async (values: any) => {
    let req = {
      // type: true,
      name: values.name,
      // user_id: loginUser.user.id,
      display_name: values.displayName,
      career_page_url_suffix: values.careerPageUrl,
    };
    setLoadingState({ createNewOrgButton: true });

    initOrganisationSetup(req)
      .then((orgRes) => {
        currentOrg(orgRes);
        setLoadingState({ createNewOrgButton: false });

        let neworg = {
          applicationId: orgRes.id,
          id: orgRes.user_id,
          username: orgRes.name,
          verified: true,
          data: {
            logo: "",
            description: "",
          },
          roles: ["OWNER"],
          display_name:orgRes.display_name?orgRes.display_name:"",
          career_page_url_suffix:orgRes.career_page_url_suffix?orgRes.career_page_url_suffix:""
        };
        addOrg(neworg);
        let updatedOrg = {
          applicationId: orgRes.id,
          id: orgRes.user_id,
          applicationName: orgRes.name,
          verified: true,
          data: {
            logo: "",
            description: "",
          },
          roles: ["OWNER"],
        };
        updateIdName(updatedOrg);
        localStorage.setItem("orgId", orgRes.id);
        if(loginUser.user?.registrations?.length >0 ){
          history.push('/o/start-setup')
        }else{
          history.push({
            pathname: `/onboarding`,
          });
        }
      })
      .catch(() => {
        // setUsernameError(error.response?.data.error.message);
        setLoadingState({ createNewOrgButton: false });
      });
  };

  const validateFromBackend = async (field: string, value: string) => {
    try {
      if (field === "name") {
        await ValidateOrganizationCompanyName(value);
      } else if (field === "displayName") {
        await ValidateOrganizationDisplayName(value)
      } else if (field === "careerPageUrl") {
        await ValidateOrganizationCareerPageURL(value)
      }
      return Promise.resolve();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return Promise.reject(new Error(errorMessage));
    }
  };

  const handleOnchange = (value:string)=>{
    setCompanyName(value);
    setUsername(value);
    setCompanyUrl(value);
  
    form.setFieldsValue({
      displayName:value ,
      careerPageUrl:value,
    });
  }

  return (
    <div>
      <div className=" flex flex-col space-y-6">
        <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Organization details")}
        </div>
        <div className="flex flex-col space-y-4 lg:text-sm text-xs">
             <div>
            <div className="tracking-wide">
              Hey{" "}
              <span className="font-medium">{loginUser.user.firstName}</span>,
            </div>
            <div>
              Thank you for choosing Tacitbase as your hiring partner. Please
              complete your organization setup.
            </div>
          </div>

          <div className="flex flex-col my-4">
            <Form
              name="normal_login"
              className="login-form text-xs w-full"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              form={form}
            >
              <div className="flex flex-col w-full space-x-0 space-y-0">
                <Form.Item
                  extra="This will appear on your invoice."
                  name="name"
                  label="Company legal name"
                  validateDebounce={1000}
                  // help={<div className="text-red-500">{usernameError && capitalizeFirstLetter(usernameError)}</div>}
                  rules={[
                    {
                      required: true,
                      //   validator: validateOrgName,
                      message: "Please provide your company legal name.",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }

                        const regex = /^[a-zA-Z0-9\s]+$/;

                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Name can only contain letters, numbers and spaces."
                          );
                        }

                        try {
                          await validateFromBackend("name", value);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage = error instanceof Error ? error.message : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }

                        // return Promise.resolve();
                      },
                    },
                  ]}
                  className=" font-medium w-full "
                >
                  <div className="flex">
                    <Input
                      placeholder="Super Nice Inc"
                      className="w-full"
                      style={{ height: 30 }}
                      maxLength={50}
                      showCount
                      value={companyName}
                      onChange={(e) => {
                        handleOnchange(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>{" "}
              </div>

              <div className="flex flex-col w-full space-x-0 space-y-0">
                <Form.Item
                  extra="This will appear on your company emails and career page."
                  name="displayName"
                  label="Display name"
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: "Please provide your Display name.",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }

                        const regex = /^[a-zA-Z0-9\s]+$/;

                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Display name can only contain letters, numbers and spaces."
                          );
                        }
                        try {
                          await validateFromBackend("displayName", value);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage = error instanceof Error ? error.message : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }
                      },
                    },
                  ]}
                  className=" font-medium w-full "
                >
                  <div className="flex">
                    <Input
                      placeholder="Super Nice"
                      className="w-full"
                      style={{ height: 30 }}
                      maxLength={50}
                      showCount
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        UsernameForPreview(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>

              <div className="flex flex-col w-full space-x-0 space-y-0">
                <Form.Item
                  extra="Your company career page URL in Tacitbase."
                  name="careerPageUrl"
                  label="Career page URL"
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: "Please provide your Career page URL",
                    },
                    {
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }

                        const regex = /^[a-zA-Z0-9-]+$/;

                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Career page URL can only contain letters, numbers and hyphen (-)."
                          );
                        }
                        try {
                          await validateFromBackend("careerPageUrl", value);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage = error instanceof Error ? error.message : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }

                      },
                    },
                  ]}
                  className=" font-medium w-full "
                >
                  <div className="flex">
                    <Input
                      placeholder="super-nice"
                      className="w-full"
                      style={{ height: 30 }}
                      addonBefore={`${process.env.REACT_APP_CAREER_PAGE_URL}/`}
                      value={companyUrl}
                      onChange={(e) => {
                        setCompanyUrl(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item shouldUpdate dependencies={["name"]}>
                {() => (
                  <div className="flex justify-end w-full space-x-2">
                    <Button
                      type="default"
                      onClick={() => {
                        form.resetFields(); 
                        history.goBack(); 
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="w-max"
                      loading={loadingState.createNewOrgButton}
                      disabled={isButtonDisabled}
                    >
                      Create
                    </Button>
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addOrg: (orgData: any) => dispatch(showOrg(orgData)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),
  tokenExchange: (
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    application_roles: Role[]
  ) =>
    dispatch(
      tokenExchange(
        token,
        applicant_id,
        applicantion_name,
        application_desc,
        application_imgURL,
        application_roles
      )
    ),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
});

export default connect(mapStateToProps, mapPropsToState)(SetupOrgname);
