import React, { Component, Suspense, lazy } from "react";
import "./styles/ats.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Spin } from "antd";
import ContentLoader from "./components/ContentLoader";
import Tacitbase from "./Tacitbase";
// import withVerificationChecks from "./OnboardingVerification";
import "react-phone-input-2/lib/style.css";
import Onboarding from "./components/signUp/Onboarding";


// Lazy-loaded components
const Board = lazy(() => import("./pages/board/Board"));
const Card = lazy(() => import("./components/card/Card"));
const CreateOrg = lazy(() => import("./pages/organisation/CreateOrg"));
const Members = lazy(() => import("./pages/Members/Member"));
const ResetPwd = lazy(() => import("./pages/resetPwd/ResetPwd"));
const ForgetPassword = lazy(() => import("./pages/login/ForgetPassword"));
const Verification  = lazy(() => import( "./pages/verification/Verification"));
const PageNotFound = lazy(() => import("./components/pageNotFound/PageNotFound"));
const FallBackErrorUI = lazy(() => import("./components/pageNotFound/FallBackErrorUI"));
const ReturnPage = lazy(() => import("./pages/CalenderReturn"));
const CandidateDBHeader = lazy(() => import("./components/candidateDB/CandidateDBHeader"));
const TmailHeader = lazy(() => import("./components/Tmail/TmailHeader"));
// const PleaseVerifyEmailPage = lazy(() => import("./pages/signUp/VerifyEmail"));
// const VerifyPhone = lazy(() => import("./pages/signUp/VerifyMobile"));
// const Username = lazy(() => import("./pages/signUp/Username"));
// const PlanPage = lazy(() => import("./pages/signUp/PlanPage"));
const OrgNamePage = lazy(() => import("./pages/signUp/CreateOrgPage"));
const OrganizationPage = lazy(() => import("./pages/signUp/OrganizationPage"));
const LoginPage = lazy(() => import("./pages/signIn/LoginPage"));
const SignUpPageLayout = lazy(() => import("./pages/signUp/SignUp"));
const NotificationCenterIndex = lazy(() => import("./components/notificationCenter/NotificationCenterIndex"));
const ContactOwnerPage = lazy(() => import("./pages/signUp/ContactOwnerPage"));
const InvitedSignUpPageLayout = lazy(() => import("./pages/signUp/InvitedSignUpPageLayout"));
const AnalyticsMainPage = lazy(() => import("./components/analyticsVersion1/AnalyticsMainPage"));
const OrgCreationStatus = lazy(() => import("./pages/signUp/OrgCreationStatus"));
const SubscriptionExpired = lazy(() => import("./pages/signUp/SubscriptionExpired"));
const JobDescriptionForm = lazy(() => import("./components/JdManagement/FormTemplate/JobDescriptionForm"));
const CommunicationIntegrationCodeHandler = lazy(() => import("./pages/CommunicationIntegrationCodeHandler"));
const ReviewIndex = lazy(() => import("./components/Reviews/ReviewIndex"));
const DetailReview = lazy(() => import("./components/Reviews/DetailReview"));
const Pool = lazy(() => import("./pages/pool/Pool"));
const BoardPanel = lazy(() => import("./components/boardPanel/BoardPanel"));
const Homepage = lazy(() => import("./components/pageContent/Homepage"));
const ApplicantDetailView = lazy(() => import("./components/jobPost/tabs/pool/ApplicantDetailView"));
// const WorkspaceCreation = lazy(() => import("./pages/signUp/WorkspaceCreation"));
const RedirectToNewCareerpage = lazy(() => import("./components/JdManagement/CareerPage/RedirectTonew").then(module => ({ default: module.RedirectToNewCareerpage })));
const RedirectToNewForm = lazy(() => import("./components/JdManagement/CareerPage/RedirectTonew").then(module => ({ default: module.RedirectToNewForm })));
const RedirectTonewJD = lazy(() => import("./components/JdManagement/CareerPage/RedirectTonew").then(module => ({ default: module.RedirectTonewJD })));

const ApplicationDetailedViewCDB = lazy(() => import("./components/CDB/ApplicationDetailedViewCDB"));
const SettingHeader = lazy(() => import("./components/setting/SettingHeader"));
const DetailedMailView = lazy(() => import("./components/mail/DetailedMailView"));
const DetailedSentMailView = lazy(() => import("./components/mail/DetailedSentMailView"));

type atsProps = {
  pageAndContentLoader: { loader: boolean; message: string; skeleton: boolean };
};
class Ats extends Component<atsProps> {
  render() { 
    return (
      <Router>
        {this.props?.pageAndContentLoader?.loader && (
          <ContentLoader
            content={<></>}
            footer={<></>}
            message={
              this.props?.pageAndContentLoader?.message &&
              this.props.pageAndContentLoader.message
            }
            loaderIcon={
              <div className="flex flex-col space-y-6">
                {" "}
                <Spin tip="" size="large">
                  <div className="content" />
                </Spin>
              </div>
            }
          />
        )}
        <Tacitbase>
        <Suspense fallback={<ContentLoader
            content={<></>}
            footer={<></>}
            message={
             ""
            }
            loaderIcon={
              <div className="flex flex-col space-y-6">
                {" "}
                <Spin tip="" size="default">
                  <div className="content" />
                </Spin>
              </div>
            }
          />}>

          <Switch>
            {/* <ProtectedRoute 
              path="/register/verification/email/:verificationId?"
              component={withVerificationChecks(PleaseVerifyEmailPage)}
            /> */}
            {/* <ProtectedRoute exact
              path="/register/verification/phone"
              component={withVerificationChecks(VerifyPhone)}
            /> */}
            {/* <ProtectedRoute exact
              path="/register/setup/username"
              component={withVerificationChecks(Username)}
            /> */}
            <Route path="/register" component={SignUpPageLayout} />
            <Route
              path="/invite-register/:email"
              component={InvitedSignUpPageLayout}
            />  
            <Route path="/onboarding/:verificationId?" component={Onboarding} />      
            <ProtectedRoute
              path="/o/start-setup"
              component={OrgCreationStatus}
            />
            {/* <ProtectedRoute
              path="/o/workspace"
              component={WorkspaceCreation}
            /> */}
            {/* <ProtectedRoute path="/o/choose-plan" component={PlanPage} />  */}
            <ProtectedRoute
              path="/o/subscription-expired"
              component={SubscriptionExpired}
            />
            <ProtectedRoute
              path="/contact-admin"
              component={ContactOwnerPage}
            />
            <ProtectedRoute
              path="/o/:org_id/updates"
              component={NotificationCenterIndex}
            />
            <ProtectedRoute path="/create-org" component={OrgNamePage} />
            <Route exact path={["/", "/login"]} component={LoginPage} />
            <Route
              exact
              path="/password/change/:changePasswordId"
              component={ResetPwd}
            />
            {/* old route of career page */}
            <Route path="/public/jd/:org_id/:jd_id" component={RedirectTonewJD} />
            <Route path="/public/jd/:org_id" component={RedirectToNewCareerpage} />

            <Route
              exact
              path="/public/form/:org_id/:form_id"
              component={RedirectToNewForm}
            />

            <ProtectedRoute
              // exact
              path="/o/:org_id/db"
              component={CandidateDBHeader}
            />
            
            <ProtectedRoute
              exact
              path="/o/:org_id/analytics"
              component={AnalyticsMainPage}
            />

            <ProtectedRoute
                exact
              path="/o/:org_id/tmail/:tabname"
              component={TmailHeader}
            />
            <Route exact path="/password/change" component={ForgetPassword} />
            <Route path="/registration/verified" component={Verification} />
            <ProtectedRoute exact path="/o" component={(OrganizationPage)} />
            <ProtectedRoute
              exact
              path="/o/:org_id/ats"
              identifire="org_id"
              component={Homepage}
            />
            <ProtectedRoute
              path="/o/:org_id/reviews"
              component={ReviewIndex}
              key=":review_id"
              identifire="review_id"
            />
            <ProtectedRoute
              exact
              path="/o/:org_id/settings/:tabname"
              component={SettingHeader}
            />
            <ProtectedRoute exact path="/createOrg" component={CreateOrg} />
            <Route
              path="/slackCode"
              component={CommunicationIntegrationCodeHandler}
            />
            <Route path="/code" component={ReturnPage} />
            <ProtectedRoute
              exact
              path="/o/:org_id/members"
              component={Members}
            />
            <Route path="/error" component={FallBackErrorUI} />

            <Route
              path="/public/jdform/:form_id"
              component={JobDescriptionForm}
            />{" "}
            <ProtectedRoute
              path="/o/:org_id/b/:board_id/:tabname"
              component={Board}
            />
            <ProtectedRoute
              path="/o/:org_id/b/:board_id/tracker"
              component={BoardPanel}
            />
            <ProtectedRoute
              path="/o/:org_id/b/:board_id/pool"
              component={Pool}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <ProtectedRoute
          // exact
            path="/o/:org_id/tmail/inbox/:candidate_id"
            component={DetailedMailView}
            // key=":candidate_id"
            // identifire="candidate_id"
          />
          <ProtectedRoute
          // exact
            path="/o/:org_id/tmail/sent/:candidate_id"
            component={DetailedSentMailView}
            // key=":candidate_id"
            // identifire="candidate_id"
          />
          <ProtectedRoute
            // exact
            path="/o/:org_id/b/:board_id/tracker/:card_id/"
            component={Card}
            // key=":card_id"
            // identifire="card_id"
          />
          <ProtectedRoute
            path="/o/:org_id/b/:board_id/pool/:candidate_id"
            component={ApplicantDetailView}
          />
          <ProtectedRoute
            path="/o/:org_id/db/:candidate_id"
            component={ApplicationDetailedViewCDB}
          />
          <ProtectedRoute
            path="/o/:org_id/reviews/:review_id/"
            component={DetailReview}
          />
          <ToastContainer autoClose={2000} transition={Slide} />
          {/* <Route render={()=>(
          <p>Not found</p>
        )}> */}
          {/* <Redirect to="/404" /> */}
            </Suspense>
        </Tacitbase>
      </Router>
    );
  }
}

const mapStateToProps = (state: any) => ({
  pageAndContentLoader: state.opening?.pageAndContentLoader,
});

export default connect(mapStateToProps)(Ats);
2