import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { useHistory, useParams } from "react-router-dom";
import { checkEmailVerify, getInvitations, getOrgDetails, getUser } from "../../lib/api";
import VerifyEmailPage from '../../pages/signUp/VerifyEmail'
import OrgNamePage from '../../pages/signUp/CreateOrgPage'
import OrgCreationStatus from "../../pages/signUp/OrgCreationStatus";
type OnboardingProps = {
  loginUser: loginUser;
  applicationId: string;
};

const Onboarding = (props: OnboardingProps) => {
  const [userState, setUserState] = useState<any>({});
  const { verificationId } = useParams<{ verificationId: string }>()
  const [allStagesDone, setAllStagesDone] = useState(false);
  const history = useHistory();


  useEffect(() => {
      // here i have to check condition if user is logout and they have a verification code
    if(!props?.loginUser){
      if(verificationId){
        checkEmailVerify(verificationId).then((res) => {
          if (res) {
            history.push("/login");
          }
        })
      }
      return;
    }
    

    if (!props.loginUser?.user?.id || props.loginUser?.user?.email === "") {
      history.push("/login");
      return;
    }
    if(props.loginUser?.user?.verified){
      getInvitations(props.loginUser.user.email).then((invitations:any)=>{
        if (invitations?.length > 0) {
          return history.push("/o");
        }
      }) 
    }
    getUser(props.loginUser.user.id).then((res: any) => {
      setUserState(res);
    });

    if (props.applicationId) {
      getOrgDetails(props.applicationId).then((appRes) => {
        const isDone = appRes.setup_stages.every((currentStage) => currentStage.stage === "done");
        setAllStagesDone(isDone);
      });
    }
  }, [props.loginUser]);

  return (
    <>
      {!userState?.verified ? (
        <VerifyEmailPage/>
      ) : userState.verified && props.loginUser?.user?.registrations?.length === 0 ? (
        <OrgNamePage/>
      ) : userState.verified && !allStagesDone ? (
        <OrgCreationStatus/>
      ) : (
        history.push("/o")
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(Onboarding);
